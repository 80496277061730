@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #1abc9c 0%,
    #2ecc71 50%,
    #3498db 100%
);

box-shadow: 0px 4.42184px 107.23px rgba(26, 188, 156, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #1abc9c 0%,
    #2ecc71 50%,
    #3498db 100%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #1abc9c 0%,
    #2ecc71 50%,
    #3498db 100%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}



@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    background: linear-gradient(
      92.23deg,
      #1abc9c 0%,
      #2ecc71 50%,
      #3498db 100%
    );
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: bold;
    font-family: 'Primary Font', sans-serif; /* Replace 'Primary Font' with your desired primary font */
  }

  .text-gradient:hover {
    background-image: linear-gradient(
      92.23deg,
      #42A6E3 0%,
      #2ecc71 50%,
      #42A6E3 100%
    );
  }

  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
